import React, { useContext, useEffect } from "react";
import Layout from "../../../components/Layout";
import CareerSlider from "../../../components/CareerSlider";
import NavigateBlock from "../../../components/NavigateBlock";
import { LogoAnimationContext } from "../../../context/LogoAnimationContext";

const CareerThankYou = () => {
    const { setCurrentAnimationName } = useContext(LogoAnimationContext);

    useEffect(() => {
        setCurrentAnimationName("send");
    }, []);

    let jobTitle = "";
    if (typeof window !== `undefined`) {
        jobTitle = new URLSearchParams(window.location.search).get("jobTitle");
    }
    return (
        <Layout>
            <section>
                <div className="career-form__end-card container container--py">
                    <div className="career-form__end-card__text">
                        <h1 className="text-h2 text-fw-medium text-clr-primary gap-3x">
                            Thank you for applying to the {jobTitle} position at Sedin.
                        </h1>
                        <p className="text-p1 text-fw-regular text-clr-secondary">
                            We'd like to inform you that we received your job application, Our
                            hiring team is currently reviewing all applications and our team will
                            reach out to you soon.
                        </p>
                    </div>
                </div>
            </section>
            <section className="career">
                <div className="container container--py">
                    <div className="text">
                        <h2 className="text-h1 text-fw-medium text-clr-primary gap-4x">
                            Explore all open positions.
                        </h2>
                    </div>
                    <div className="career-slider-wrapper">
                        <CareerSlider></CareerSlider>
                    </div>
                </div>
            </section>
            <section className="career__services-block">
                <div className="container container--last">
                    <NavigateBlock />
                </div>
            </section>
        </Layout>
    );
};

export default CareerThankYou;
